import React, { useEffect, useState } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import _, { split } from "lodash";

import DialogCommonDefault from "../../../../components/dialogs/DialogCommonDefault";
import { buttonColors } from "../../../../utils/enum";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import IncrementDecrementTextboxNormal from "../../../../components/common/IncrementDecrementTextbox/IncrementDecrementTextboxNoraml";

export interface StockLevelModalProps {
  isOpen: any;
  setIsOpen: any;
  handleCreate: any;
}

const ConnectExistingChainModal: React.FC<StockLevelModalProps> = ({
  isOpen,
  setIsOpen,
  handleCreate,
}) => {
  return (
    <DialogCommonDefault
      open={isOpen}
      setOpen={setIsOpen}
      isNeedFixedHeight={false}
      backgroundColor="entity_background"
      maxWidth="xs"
    >
      <DialogTitle>Create a New Chain</DialogTitle>
      <DialogContent></DialogContent>
      <DialogActions style={{ marginBottom: "12px" }}>
        <ButtonCommon
          onClick={() => setIsOpen(false)}
          variant="contained"
          style={{ fontSize: 11, width: "120px", marginRight: "12px" }}
          color={buttonColors.CANCEL_BUTTON_COLOR}
        >
          Cancel
        </ButtonCommon>

        <ButtonCommon
          onClick={handleCreate}
          variant="contained"
          style={{ fontSize: 11, width: "120px", marginRight: "14px" }}
          color={buttonColors.CREATE_BUTTON_COLOR}
        >
          Create
        </ButtonCommon>
      </DialogActions>
    </DialogCommonDefault>
  );
};

export default ConnectExistingChainModal;
