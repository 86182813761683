import React, { useState } from "react";
import {
  createStyles,
  FormControlLabel,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import _ from "lodash";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import CardCommon from "../../../../components/card/CardCommon";
import { Autocomplete } from "@material-ui/lab";
import SwitchCommon from "../../../../components/switches/SwitchCommon";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import { buttonColors } from "../../../../utils/enum";
import { useRouteMatch } from "react-router-dom";
import CreateNewChain from "./CreateNewChain";
import ConnectExistingChainModal from "./ConnectExistingChainModal";
import { createNewChain } from "../../../../services/locationApp/chainConfiguration";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import {
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  SUCCESSFULLY_CREATED,
} from "../../../../utils/consts";

export interface StockConfigurationNodeProps {}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    titleColor: {
      color: theme.palette.custom.orange.contrastText,
    },
    autoComplete: {
      marginTop: "4px",
      "& .MuiAutocomplete-input": {
        color: theme.palette.custom.orange.contrastText,
      },
      "& .MuiInputBase-root": {
        color: "inherit",
        backgroundColor: theme.palette.background.entity_background,
        borderRadius: 10,
      },
      [`& fieldset`]: {
        borderRadius: 10,
        border: "none",
        color: theme.palette.custom.orange.contrastText,
      },
    },
  }),
);

/**
 * Component: ChainConfigurationNode
 *
 * Description:
 * This component represents a node in the Stock Configuration page.
 * It provides controls for managing stock-related configurations, such as enabling/disabling stock,
 * enabling/disabling master location, selecting the department, and saving changes.
 **/

const ChainConfigurationNode: React.FunctionComponent<
  StockConfigurationNodeProps
> = ({}) => {
  const [openChainCreateModal, setOpenChainCreateModal] = useState(false);
  const [isOpenConnectExistingChainModal, setIsOpenConnectExistingChainModal] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const classes = useStyles();
  const match: any = useRouteMatch();

  const handleOpenCreateNewChain = () => {
    setOpenChainCreateModal(true);
  };

  const handleCreateNewChain = async (name: string) => {
    try {
      setIsLoading(true);
      const data = { name, primaryLocationId: match.params.locationId };
      const res = await createNewChain(match.params.locationId, data);
      setSuccess(SUCCESSFULLY_CREATED);
      setIsLoading(false);
      setOpenChainCreateModal(false);
    } catch (error) {
      setIsLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  const handleOpenConnectExistingChainModal = () => {
    setIsOpenConnectExistingChainModal(true);
  };

  const handleConnectExistingChain = () => {};

  return (
    <>
      <Grid container>
        <Grid item xs={12} style={{ display: "flex", justifyContent: "start" }}>
          <div style={{ display: "flex" }}>
            <ButtonCommon
              onClick={handleOpenCreateNewChain}
              variant="contained"
              style={{
                fontSize: 11,
                height: "200px",
                width: "200px",
                textTransform: "none",
                marginRight: "12px",
              }}
              color={buttonColors.CREATE_BUTTON_COLOR}
            >
              <Typography variant="h6" style={{ color: "white" }}>
                Create a New Chain
              </Typography>
            </ButtonCommon>
            <ButtonCommon
              onClick={handleOpenConnectExistingChainModal}
              variant="contained"
              style={{
                fontSize: 11,
                height: "200px",
                width: "200px",
                textTransform: "none",
              }}
              color={buttonColors.CREATE_BUTTON_COLOR}
            >
              <Typography variant="h6">Connect to a existing Chain</Typography>
            </ButtonCommon>
          </div>
        </Grid>
      </Grid>

      <CreateNewChain
        isOpen={openChainCreateModal}
        setIsOpen={setOpenChainCreateModal}
        handleCreate={handleCreateNewChain}
        isLoading={isLoading}
      />

      <ConnectExistingChainModal
        isOpen={isOpenConnectExistingChainModal}
        setIsOpen={setIsOpenConnectExistingChainModal}
        handleCreate={handleConnectExistingChain}
      />

      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />

      <DefaultAlert
        open={!!success}
        handleClose={() => setSuccess("")}
        message={success}
        severity={"success"}
      />
    </>
  );
};

// Apply the WithLoading higher-order component to the ChainConfigurationNode component
export default ChainConfigurationNode;
