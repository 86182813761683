import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";

import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import { Box } from "@material-ui/core";
import _ from "lodash";
import { fetchAllFilterLocations } from "../../../../services/locationApp/locationFilterService";
import {
  createMasterLocations,
  fetchAllMasterLocations,
  updateMasterLocations,
} from "../../../../services/inventory/masterLocations";
import InventoryDetailsDefault from "../../../../components/common/InventoryDetails/InventoryDetailsDefault";
import ChainConfigurationNode from "./ChainConfigurationNode";
import { getFilterListFromArrayObject } from "../../../../utils/commonArrayMap";
import AlertDialog from "../../../../components/alerts/AlertDialog";

interface Location {
  id: string;
  businessDisplayName: string;
}

/**
 * ChainConfiguration Component:
 * This React component manages the configuration of stock departments for a given inventory location.
 * It includes functionality to fetch and display master locations, create or update stock configurations,
 * and handle activation requirements. The component is integrated with authentication checks to ensure
 * the user has the necessary authority.
 *
 * Component Structure:
 * - State variables manage component state, including error messages, activation status, and configuration details.
 * - Functions handle API calls, stock configuration updates, and location-specific actions.
 * - useEffect fetches location information and sets the document title on component mount.
 * - JSX structure includes the main container, inventory details component, and alert dialogs.
 *
 * Note: The component is wrapped with the withAuthority HOC to enforce user authority checks.
 */
const ChainConfiguration: React.FunctionComponent = () => {
  const [error, setError] = useState("");
  const [isStockEnable, setIsStockEnable] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [ 
    successfullyChangeStockConfiguration,
    setSuccessfullyChangeStockConfiguration,
  ] = useState(false);
  const [locationSelectorList, setLocationSelectorList] = useState([]);
  const [isOpenSkeletonLoading, setIsOpenSkeletonLoading] = useState(true);
  const [success, setSuccess] = useState("");

  const match: any = useRouteMatch();


  /**
   * Effect to set the document title and fetch location information on component mount.
   */
  useEffect(() => {
    // Set the document title to "Eat Presto - Stock Department"
    document.title = "Inventory - Stock Configuration";

    // Call the function to fetch master locations when the component mounts
    getAllFilterLocation();
  }, []);

  /**
   * Function to fetch location information and initialize stock department loading.
   * @param {string} searchName - Name to search for locations.
   */
  const getAllFilterLocation = async () => {
    try {
      // Attempt to fetch location information using the 'fetchAllFilterLocations' API
      const res = await fetchAllFilterLocations("");

      // Initialize an array to hold location data
      let locationList: any = [];

      // Check if location data is not empty in the response
      if (!_.isEmpty(res.data.data)) {
        locationList.push({
          id: match.params.locationId,
          label: "Master Location Not Required",
        });
        // Iterate through each location in the response data
        res.data.data.forEach((locationData: Location) => {
          // Push an object with correct property name and label properties to the locationList array
          locationList.push({
            id: locationData.id, // Use the correct property name 'locationId'
            label: locationData.businessDisplayName,
          });
        });
      }
      // Update the 'locationSelectorList' state with the fetched location list
      setLocationSelectorList(locationList);
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsOpenSkeletonLoading(false);
    }
  };

  return (
    <>
      {/* Main container */}
      <Box>
        <InventoryDetailsDefault
          locationSelectorList={locationSelectorList}
          isOpenSkeletonLoading={isOpenSkeletonLoading}
          topic="Chain Configuration"
          isNeedAddButton={false}
          nodeList={[1]}
          isActiveLocationId={true}
        >
          <ChainConfigurationNode
          />
        </InventoryDetailsDefault>
        {/* DefaultAlert components for success and error messages */}
        <DefaultAlert
          open={!!success}
          handleClose={() => setSuccess("")}
          message={success}
          severity={"success"}
        />
        <DefaultAlert
          open={!!error}
          handleClose={() => setError("")}
          message={error}
          severity="error"
        />
      </Box>

      {!isStockEnable && (
        <AlertDialog
          open={!isStockEnable}
          title="Activation Required"
          desc={errorMessage}
          severity="error"
          confirmLabel="Ok"
          disableBackdropClick
          confirmAction={() => {
            setIsStockEnable(true);
          }}
          color="secondary"
        />
      )}

      {successfullyChangeStockConfiguration && (
        <AlertDialog
          open={successfullyChangeStockConfiguration}
          title="Success"
          desc="Successfully change stock configuration."
          severity="success"
          confirmLabel="Ok"
          disableBackdropClick
          confirmAction={() => {
            setSuccessfullyChangeStockConfiguration(false);
          }}
          color="secondary"
        />
      )}
    </>
  );
};

export default withAuthority(
  ChainConfiguration,
  Authorities.STOCK_CONFIGURATION_READ,
);
