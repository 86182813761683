import React from "react";
import { Route, Switch } from "react-router-dom";
import BasicInfo from "../pages/BasicInfo";
import OpeningTimes from "../pages/OpeningTimes";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import Authorities from "../../../auth/authorities";
import { getIsAuthorized } from "../../../utils/authorities";
import Location from "../pages/Location";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Dashboard from "../pages/Dashboard";
import StorefrontIcon from "@material-ui/icons/Storefront";
import { Icon } from "@iconify/react";
import PaymentGateway from "../pages/PaymentGateway";
import UsersAndPermission from "../pages/UsersAndPermission";
import ChainConfiguration from "../pages/ChainConfiguration";

export const getSidebarRoutes = () => {
  const sidebarRoute = [
    {
      id: "DASHBOARD",
      title: "Dashboard",
      path: "",
      icon: <DashboardIcon />,
      isAuthorized: getIsAuthorized(Authorities.LOCATION_DASHBOARD_READ),
    },
    {
      id: "BASIC_INFO",
      title: "Business Info",
      path: "/basic-info",
      icon: <StorefrontIcon />,
      isAuthorized: getIsAuthorized(Authorities.BASIC_INFO_READ),
    },
    {
      id: "OPENING_TIMES",
      title: "Opening Times",
      path: "/opening-times",
      icon: <AccessTimeIcon />,
      isAuthorized: getIsAuthorized(Authorities.OPENING_TIME_READ),
    },
    {
      id: "internal",
      title: "Internal Configurations",
      path: "/internalConfiguration",
      icon: <Icon icon="ri:admin-line" height="24" width="24" />,
      isAuthorized: getIsAuthorized(Authorities.PAYMENT_CONFIGURATION_READ),
      childRoutes: [
        {
          id: "internalConfiguration",
          title: "Payment Configuration",
          path: "/internalConfiguration/paymentConfiguration",
          icon: <Icon icon="dashicons:admin-generic" height="24" width="24" />,
          isAuthorized: getIsAuthorized(Authorities.PAYMENT_CONFIGURATION_READ),
        },
        {
          id: "USERS_PERMISSION",
          title: "Users & Permissions",
          path: "/users-permission",
          icon: <AccessTimeIcon />,
          isAuthorized: getIsAuthorized(Authorities.USERS_PERMISSION_READ),
        },
        {
          id: "CHAIN_CONFIGURATION",
          title: "Chain Configuration",
          path: "/internalConfiguration/chainConfiguration",
          icon: <AccessTimeIcon />,
          isAuthorized: getIsAuthorized(Authorities.USERS_PERMISSION_READ),
        },
      ],
    },
  ];
  return sidebarRoute;
};

export interface LocationAppRoutesProps {}

const LocationAppRoutes: React.FunctionComponent<
  LocationAppRoutesProps
> = () => {
  return (
    <Switch>
      <Route path="/:locationId/presto-locations" component={Dashboard} exact />
      <Route
        path="/:locationId/presto-locations/opening-times"
        component={OpeningTimes}
      />
       <Route
        path="/:locationId/presto-locations/users-permission"
        component={UsersAndPermission}
      />
      <Route
        path="/:locationId/presto-locations/basic-info"
        component={BasicInfo}
      />
      <Route
        path="/:locationId/presto-locations/internalConfiguration/paymentConfiguration"
        component={PaymentGateway}
        exact
      />
      <Route
        path="/:locationId/presto-locations/internalConfiguration/chainConfiguration"
        component={ChainConfiguration}
        exact
      />
      <Route path="/" component={Location} exact />
    </Switch>
  );
};

export default LocationAppRoutes;
